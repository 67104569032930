import { Vue, Component } from 'vue-facing-decorator';
import { ElMessage, ElNotification, ElMessageBox, MessageOptions } from 'element-plus';
import { CircleCheckFilled, Ticket, Minus, Clock, ArrowLeft, ArrowRight } from '@element-plus/icons-vue';
import Header from '@/components/Header/Header.vue';
import DemoHeader from '@/components/DemoHeader/DemoHeader.vue';
import TestHeader from '@/components/TestHeader/TestHeader.vue';
import QuestionSelect from '@/components/QuestionSelect/QuestionSelect.vue';
import QuestionPanel from '@/components/QuestionPanel/QuestionPanel.vue';
import Footer from '@/components/Footer/Footer.vue';
import Help from '@/components/Help/Help.vue';
import demoData from '@/assets/demo.json';
import questionsData from '@/assets/questions.json';
import optionsData from '@/assets/options.json';

@Component(
{
    components:
    {
        Header,
        DemoHeader,
        TestHeader,
        Footer,
		CircleCheckFilled,
        Ticket,
        Minus,
		Clock,
		ArrowLeft,
		ArrowRight,
        QuestionSelect,
        QuestionPanel,
        Help
    },
})

export default class Demo extends Vue 
{
    private loading : boolean = true;
    private windowWidth : number = window.innerWidth;
    private windowHeight : number = window.innerHeight;
    private clipData : string = '';
    private token : string = '';
    private tokenArray : string[] = ['', '', '', '', '', '', '', ''] as string[];
    private showButton : boolean = false;
    private keyCode : any = '';
    private demoModel : Object[] = demoData;
    private questionList : Object[] = questionsData;
    private optionList : Object[] = optionsData;
    private minutesLeft : number = 10;
    private questionid : number = 1;
	private questions_total : number = 5;
	private questions_answered : number = 0;
    private complexity : number = 0;
    private ticket : string = 'MC-HEALTH';
    private testDate : string = '23.05.2042 - 13:37';
    private targetGroup : string = 'Uneingeschränkter Heilpraktiker';
    private phase : number = 0;
	private showNext : boolean = true;
	private showPrev : boolean = false;
    private showHint1 : boolean = false;
    private showHint2 : boolean = false;
    private showHint3 : boolean = false;

    public async mounted()
    {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;

        window.addEventListener('resize', this.onWindowResize);
        window.addEventListener('beforeunload', this.beforeUnload);
        window.scrollTo(0,0);

        this.loading = false;
    }

    private onWindowResize(e : any)
    {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }
    
    private beforeUnmount()
    {
        window.removeEventListener('resize', this.onWindowResize);
        window.removeEventListener('beforeunload', this.beforeUnload);
    }    

    private beforeUnload(e : any) 
    {
        if (this.phase > 0)
        {
            e.preventDefault();
            return 'Wenn Sie die Seite aktualisieren, müssen Sie die Demo wieder von vorn beginnen. Sind Sie sicher?';
        }
    }
    
    private showHint()
    {
      switch (this.phase)
      {
        case 1:
              this.showHint1 = true;
              this.checkToken();
          break;

        case 2:
          this.showHint2 = true;
                  break;

              case 3:
                  this.showHint3 = true;
                  break;
      };
    }

    public onKeyDown(event : any, index : number)
    {
        if (event.keyCode == 13)
        {
            this.onSubmit();
            return;
        }

        if (index > 0 && event.keyCode == 8 && this.tokenArray[index].length == 0)
        {
            let el : HTMLInputElement = document.getElementById("token-" + (index-1)) as HTMLInputElement;
    
            if (el)
            {
                el.focus();
            }   
        }
    }

    private processTime()
    {
        if (this.minutesLeft > 0)
        {
            this.minutesLeft--;
            window.setTimeout(this.processTime, 60000);
        }
        else
        {
            this.phase = 6;
        }
    }

    private async processPhase()
    {
        switch (this.phase)
        {
            case 0:
                this.phase = 1;
                window.setTimeout(this.showHint, 1000);
                break;

            case 1:
                if (this.token == 'MC-HEALTH')
                {
                    this.phase = 2;
        			window.setTimeout(this.showHint, 1000);
                    return;
                }

                // in case login was not successful, show error message
                ElNotification(
                {
                    title: 'Fehler',
                    message: 'Das von Ihnen eingegebene Ticket ist entweder ungültig, oder die Prüfung hat noch nicht begonnen.',
                    type: 'error',
                    offset: 16,
                    duration: 5000
                });
                break;

            case 2:
				this.phase = 3;
                window.setTimeout(this.processTime, 60000);
                break;
        };
    }

    private checkAnswers()
    {
        let totalAnswers : number = 0;
        for (let question : number = 0; question < 5; question++)
        {
            this.questionList[question].isanswered = false;
            for (let opt : object of this.optionList[this.complexity][question])
            {
                if (opt.isselected === true)
                {
                    totalAnswers++;
                    this.questionList[question].isanswered = true;
                    break;
                }
            }
        }
        this.questions_answered = totalAnswers;
    }

    private checkToken()
    {
        this.showButton = false;

        if (this.token == 'MC-HEALTH')
        {
            this.showButton = true;
        }
    }
    
    private onTokenEnter(inputField : HTMLInputEvent, index : number)
    {
        if (!inputField.data) 
        {
            this.checkToken();
            return;
        }

        let data : string | undefined = inputField.data;
		
        // if only only one character is enered, store it and move to next one
        if (data.length == 1)
        {
            this.tokenArray[index] = this.tokenArray[index].toUpperCase();

            if (index < 7)
            {
                let el : HTMLInputElement = document.getElementById("token-" + (index+1)) as HTMLInputElement;
    
                if (el)
                {
                    el.focus();
                }   
            }
        }

        this.token = '';
        for (let i : number = 0; i < 8; i++)
        {
            if (i == 2) this.token += '-';
            this.token += this.tokenArray[i];
        }

        this.checkToken();
    }

    private onContinue()
    {
        switch (this.phase)
        {
			case 1:
        		this.showHint1 = false;
				break;

			case 2:
		        this.showHint2 = false;
				window.setTimeout(this.processPhase, 5000);
				break;
		};
    }

    private onSubmit()
    {
        if (this.token.length > 4)
        {
            this.processPhase();
        }
        else
        {
            // or show error message
            ElNotification(
            {
                title: 'Fehler',
                message: 'Die von Ihnen eingegebene Teilnehmer-ID ist ungültig. Bitte versuchen Sie es erneut.',
                type: 'error',
                offset: 16
            });
        }
    }

    private onStartClick(complexity : number)
    {
        this.complexity = complexity;
        this.processPhase();
    }

    private onQuestionSelect(questionid : number)
    {
        this.questionid = questionid;
    }

    private onAnswerClick()
    {
        this.checkAnswers();
    }

    private onHelpClick()
    {
        this.showHint();
    }

    private onFinishTestClick()
    {
        this.phase = 9;
    }

    private onCancelFinishClick()
    {
        this.phase = 3;
    }

    private onConfirmFinishClick()
    {
        this.phase = 6;
    }

	private onPreviousQuestionClick()
	{
        if (this.questionid > 1)
        {
            this.questionid--;
        }
    }

    private onNextQuestionClick()
    {
        if (this.questionid < 5) 
        {
            this.questionid++;
        }
    }

    private onGoHomeClick()
    {
        this.$router.push("/")
    }
}
