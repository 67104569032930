import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home/Home.vue'
import HealthDepartments from '../views/HealthDepartments/HealthDepartments.vue'
import Participants from '../views/Participants/Participants.vue'
import Tests from '../views/Tests/Tests.vue'
import Demo from '../views/Demo/Demo.vue'
import Terms from '../views/Terms/Terms.vue'
import Presentation from '../views/Presentation/Presentation.vue'
import Board from '../views/Board/Board.vue'
import Access from '../views/Access/Access.vue'
import Design from '../views/Design/Design.vue'

const routes: Array<RouteRecordRaw> = 
[
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/healthdepartments',
        name: 'healthdepartments',
        component: HealthDepartments
    },
    {
        path: '/participants',
        name: 'participants',
        component: Participants
    },
    {
        path: '/tests',
        name: 'tests',
        component: Tests
    },
    {
        path: '/demo',
        name: 'demo',
        component: Demo 
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms 
    },
    {
        path: '/20241217',
        name: 'access',
        component: Presentation
    },
    {
        path: '/design',
        name: 'design',
        component: Design 
    },
    {
        path: '/4b0c99ef62adf404996efa9f633cc6f4',
        name: 'board',
        component: Board
    },
]

const router = createRouter(
{
    history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
    routes
});

export default router


